<script lang="ts">
  let key = location.pathname.substring(1);
  import Call from "./Call.svelte";
  import Homepage from "./Homepage.svelte";
</script>

{#if key === ""}
  <Homepage />
{:else}
  <Call {key} />
{/if}
