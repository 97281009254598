import firebase from "firebase/app";
import "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyDdK2lXjI9wJYGVOz4LO0XJx314vI-Xtts",
    authDomain: "shader-party.firebaseapp.com",
    databaseURL: "https://shader-party.firebaseio.com",
    projectId: "shader-party",
    storageBucket: "shader-party.appspot.com",
    messagingSenderId: "670108729164",
    appId: "1:670108729164:web:0da02777f27607263f19e9",
    measurementId: "G-SNLQ49X8CF",
};
export const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(firebaseConfig);
