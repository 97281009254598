import { writable } from "svelte/store";
export const people = writable({});
export const text = writable(`precision mediump float;
uniform sampler2D videoTex;

varying vec2 uv;

void main () {
  vec4 color = texture2D(videoTex, uv);
  gl_FragColor = color;
}`);
