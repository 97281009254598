<script lang="ts">
  import { onMount } from "svelte";
  import CodeMirror from "codemirror";
  import "codemirror/lib/codemirror.css";

  import "codemirror/mode/clike/clike";
  import "codemirror/addon/edit/closebrackets";
  import "codemirror/addon/search/match-highlighter";
  import "codemirror/addon/selection/mark-selection";
  import "codemirror/addon/comment/comment";

  import { text } from "./stores.js";

  let textarea: HTMLTextAreaElement;

  onMount(() => {
    const editor = CodeMirror.fromTextArea(textarea, {
      value: $text,
      mode: { globalVars: true, name: "x-shader/x-fragment" },
      extraKeys: {
        Enter: "newlineAndIndentContinueMarkdownList",
        "Ctrl-/": "toggleComment",
        "Cmd-/": "toggleComment",
      },

      autoCloseBrackets: true,
      highlightSelectionMatches: true,
      lineWrapping: true,
      showCursorWhenSelecting: true,
      styleSelectedText: true,
    });
    let updatingExternally = false;
    editor.on("change", (instance) => {
      if (!updatingExternally) text.set(instance.getValue());
    });
    editor.refresh();
    text.subscribe((v) => {
      if (editor.getValue() === v) return;
      updatingExternally = true;
      editor.setValue(v);
      updatingExternally = false;
    });
  });
</script>

<div class="container">
  <textarea bind:this={textarea} value={$text} readonly />
</div>

<style>
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .container :global(.CodeMirror) {
    height: 100%;
    background: transparent;
  }
</style>
