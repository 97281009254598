<script lang="ts">
  let key: string;
</script>

<main>
  <h1>shader.party</h1>
  <p>
    To start pick a room name. Anybody who guesses it can join, so make it long!
  </p>
  <form
    on:submit|preventDefault={() => {
      if (key) window.location.pathname = key;
    }}
  >
    <input placeholder="room key" bind:value={key} />
    <button type="submit"> Join / Create </button>
  </form>
  <p>
    What is shader.party? Eventually it will be video chat with editable
    shaders, but it's only about 20% done for now, thanks for your patience!
  </p>
  <p>Ideas? Want to help? Email this domain at gmail.</p>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #6aa84f;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
