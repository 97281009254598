<script lang="ts">
  import { onMount } from "svelte";
  import regl from "regl";
  import { people, text } from "./stores.js";

  let canvas: HTMLCanvasElement;

  onMount(() => {
    console.log($people);
    const gl = regl({ canvas: canvas });
    const videoTextures: { [key: string]: regl.Texture2D } = {};
    const videoTicks: { [key: string]: regl.Cancellable } = {};
    let draw = () => {};
    people.subscribe((peeps) => {
      let changed = false;
      Object.entries(peeps).forEach(([key, person]) => {
        if (!videoTextures[key] && person.video && person.dims?.h > 0) {
          changed = true;
          videoTextures[key] = gl.texture(person.video);
          videoTicks[key] = gl.frame(() =>
            videoTextures[key].subimage(person.video)
          );
        }
      });
      Object.keys(videoTextures).forEach((key) => {
        if (peeps[key]?.video && peeps[key].dims?.h > 0) return;
        changed = true;
        videoTicks[key].cancel();
        delete videoTextures[key];
        delete videoTicks[key];
      });
      if (changed) {
        console.log(videoTextures);

        draw = gl({
          uniforms: {
            videoTex: Object.values(videoTextures)[0],
            screenShape: ({ viewportWidth, viewportHeight }) => [
              viewportWidth,
              viewportHeight,
            ],
            time: gl.context("time"),
          },
          frag: () => $text,

          vert: () => `precision mediump float;
  attribute vec2 position;
  varying vec2 uv;
  void main () {
    uv = position;
    gl_Position = vec4(1.0 - 2.0 * position, 0, 1);
  }`,

          attributes: {
            position: [
              [-2, 0],
              [0, -2],
              [2, 2],
            ],
          },

          count: 3,
        });
      }
    });

    const frame = gl.frame(() => {
      try {
        draw();
      } catch (e) {
        console.log("Draw error: ", e);
      }
    });
    return () => frame.cancel();
  });
</script>

<canvas width="1280" height="720" bind:this={canvas} />

<style>
  canvas {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  canvas {
    display: block;
  }
</style>
