<script lang="ts">
  import { writable, derived } from "svelte/store";
  import { app } from "./firebase";
  import type { Room, Person, PersonVideo, Message } from "./types";
  import firebase from "firebase/app";
  import Canvas from "./Canvas.svelte";
  import Editor from "./Editor.svelte";

  export let key: string;

  const ICE_SERVERS = [
    { urls: "stun:stun.l.google.com:19302" },
    { urls: "stun:stun.stunprotocol.org:3478" },
    { urls: "stun:stun.sipnet.net:3478" },
    { urls: "stun:stun.ideasip.com:3478" },
    { urls: "stun:stun.iptel.org:3478" },
    {
      urls: "turn:numb.viagenie.ca",
      username: "shaderparty@gmail.com",
      credential: "KHY@XBE9Xqn!B7C",
    },
    {
      urls: [
        "turn:173.194.72.127:19305?transport=udp",
        "turn:[2404:6800:4008:C01::7F]:19305?transport=udp",
        "turn:173.194.72.127:443?transport=tcp",
        "turn:[2404:6800:4008:C01::7F]:443?transport=tcp",
      ],
      username: "CKjCuLwFEgahxNRjuTAYzc/s6OMT",
      credential: "u1SQDR/SQsPQIxXNWQT7czc/G4c=",
    },
  ];

  import { people, text } from "./stores.js";

  const room = writable<Room>({});
  const messages = writable<Message[]>([]);

  const audioId = writable<string | undefined>(localStorage.getItem("audioId"));
  audioId.subscribe((v) => localStorage.setItem("audioId", v));
  const videoId = writable<string | undefined>(localStorage.getItem("videoId"));
  videoId.subscribe((v) => localStorage.setItem("videoId", v));
  const audioInputs = writable<MediaDeviceInfo[]>([]);
  const audioOutputs = writable<MediaDeviceInfo[]>([]);
  const videoInputs = writable<MediaDeviceInfo[]>([]);
  const mediaStream = writable<MediaStream | undefined>(undefined);

  let commentText: string;

  const roomRef = app.database().ref(`room/${key}`);
  const codeRef = app.database().ref(`code/${key}`);
  const peopleRef = app.database().ref(`people/${key}`);
  const peersRef = app.database().ref(`peers/${key}`);
  const messagesRef = app.database().ref(`messages/${key}`);

  const meKey = peopleRef.push({
    name:
      localStorage.getItem("name") || Math.random().toString(36).substring(2),
    color:
      localStorage.getItem("color") ||
      "#" + Math.floor(Math.random() * 16777215).toString(16),
  }).key;

  peopleRef.child(meKey).onDisconnect().remove();
  peersRef.child(meKey).onDisconnect().remove();

  const me = derived(
    people,
    (peeps) =>
      (peeps[meKey] || {
        name: "Loading...",
        color: "#888888",
      }) as Person
  );

  roomRef.on("value", (snapshot) => {
    room.set(snapshot.val() as Room);
    console.log("room", $room);
  });

  codeRef.on("value", (snapshot) => {
    text.set(snapshot.val() as string);
    console.log("code", $text);
  });
  text.subscribe((value) => {
    codeRef.set(value);
  });

  const myPeersRef = peersRef.child(`/${meKey}`);
  myPeersRef.child("offers").on("child_added", (data) => {
    const offer = data.val() as RTCSessionDescription;
    const person = $people[data.key];
    console.log(`incoming new offer from ${person.name} - ${offer.type}`);
    if (person && person.connection) {
      console.log("setting remote", offer.type);
      person.connection
        .setRemoteDescription(offer)
        .then(() => {
          if (offer.type === "offer")
            return person.connection
              .createAnswer()
              .then((answer) => person.connection.setLocalDescription(answer))
              .then(() => {
                console.log(
                  `setting answer ${data.key}/offers/${meKey}`,
                  person.connection.localDescription
                );
                return peersRef
                  .child(`${data.key}/offers/${meKey}`)
                  .set(person.connection.localDescription.toJSON());
              });
        })
        .catch((err) => console.log("Error setting remote description: ", err));
    }
    data.ref.remove();
  });

  myPeersRef.child("iceCandidates").on("child_added", (data) => {
    const candidate = data.val() as RTCIceCandidateInit;
    const person = $people[data.key];
    console.log(
      `new ice candidate from ${person.name} - ${candidate.candidate}`
    );
    if (person && person.connection) {
      console.log("adding ice candidate", candidate);
      person.connection
        .addIceCandidate(candidate)
        .catch((err) => console.log("Error adding ice candidate: ", err));
    }
    data.ref.remove();
  });

  peopleRef.on("child_added", (data) => {
    let person = data.val() as PersonVideo;
    if (data.key !== meKey) {
      const connection = new RTCPeerConnection({ iceServers: ICE_SERVERS });
      connection.onicecandidate = (ev) => {
        console.log("outgoing ice candidate", ev?.candidate?.candidate);
        if (ev.candidate) {
          peersRef
            .child(`${data.key}/iceCandidates/${meKey}`)
            .set({
              candidate: ev.candidate.candidate,
              sdpMLineIndex: ev.candidate.sdpMLineIndex,
            })
            .catch((err) => console.log("Error sending ice candidate: ", err));
        }
      };
      connection.ontrack = ({ streams: [stream] }) => {
        console.log(`onTrack from ${person.name}`, stream, $people);
        $people[data.key].video.srcObject = stream;
      };
      mediaStream.subscribe((stream) => {
        console.log(`mediaStream.subscribe for ${person.name}`, stream);
        stream &&
          stream.getTracks().forEach((t) => connection.addTrack(t, stream));
      });
      connection.oniceconnectionstatechange = (e) =>
        console.log(
          "oniceconnectionstatechange",
          connection.iceConnectionState
        );
      connection.onicegatheringstatechange = (e) =>
        console.log("onicegatheringstatechange", connection.iceGatheringState);
      connection.onsignalingstatechange = (e) =>
        console.log("onsignalingstatechange", connection.signalingState);
      connection.onnegotiationneeded = (e) => {
        console.log("onnegotiationneeded", connection.signalingState);
        /*console.log(`Offer for ${data.key}: ${data.key > meKey}`);
        if (data.key > meKey) {
          // The higher ID creates the offer*/
        if (connection.signalingState != "stable") {
          console.log("The connection isn't stable yet; postponing...");
          return;
        }
        connection
          .createOffer()
          .then((offer) => {
            console.log("Created offer", offer.type);
            return connection.setLocalDescription(offer);
          })
          .then(() => {
            console.log("Setting offer", connection.localDescription.type);
            return peersRef
              .child(`${data.key}/offers/${meKey}`)
              .set(connection.localDescription.toJSON());
          })
          .catch((err) => console.log("Error sending offer: ", err));
        //}
      };
      person = { ...person, connection: connection };
    }
    people.update((peeps) => ({
      ...peeps,
      [data.key]: person,
    }));
  });
  peopleRef.on("child_changed", (data) => {
    people.update((peeps) => ({
      ...peeps,
      [data.key]: { ...peeps[data.key], ...(data.val() as Person) },
    }));
    console.log("changed people", $people);
  });
  peopleRef.on("child_removed", (data) => {
    people.update(({ [data.key]: _, ...rest }) => rest);
    console.log("deleted people", $people);
  });

  messagesRef.on("child_added", (data) => {
    messages.update((msgs) => [
      ...msgs,
      {
        key: data.key,
        ...data.val(),
      },
    ]);
  });

  const populateDevices = (devices: MediaDeviceInfo[]) => {
    videoInputs.set(devices.filter((d) => d.kind === "videoinput"));
    audioInputs.set(devices.filter((d) => d.kind === "audioinput"));
    audioOutputs.set(devices.filter((d) => d.kind === "audiooutput"));
  };
  navigator.mediaDevices
    .enumerateDevices()
    .then(populateDevices)
    .catch((e) => console.log("Error with initial enumerateDevices", e));
</script>

<main>
  <Canvas />
  <div class="controls">
    <div class="editor">
      <Editor />
    </div>
    <div class="room">
      <p>
        Room key: {key}
      </p>
      <p>
        You:
        <input
          type="color"
          value={$me.color}
          on:input={(e) => {
            const color = e.currentTarget.value;
            peopleRef
              .child(`${meKey}/color`)
              .set(color)
              .then(() => localStorage.setItem("color", color));
          }}
        />
        <input
          value={$me.name}
          on:input={(e) => {
            const name = e.currentTarget.value;
            peopleRef
              .child(`${meKey}/name`)
              .set(name)
              .then(() => localStorage.setItem("name", name));
          }}
        />
      </p>
    </div>
    <div class="messages">
      <p>Messages:</p>
      <ul>
        {#each $messages as message (message.key)}
          <li>
            <span
              style="color: {$people[message.authorId]?.color ||
                'rgb(0, 0, 0, 0.5);'}"
              >{$people[message.authorId]?.name || message.authorName}: {message.text}</span
            >
          </li>
        {/each}
      </ul>
      <form
        on:submit|preventDefault={() => {
          if (commentText)
            messagesRef.push({
              text: commentText,
              authorName: $me.name,
              authorId: meKey,
              time: firebase.database.ServerValue.TIMESTAMP,
            });
          commentText = "";
        }}
      >
        <input placeholder="Message..." bind:value={commentText} />
        <button type="submit">Send</button>
      </form>
    </div>

    <div class="people">
      {#each Object.entries($people) as [key, person] (key)}
        <div
          class="person"
          style={`width: ${
            person.dims?.h > 0 ? (200 * person.dims.w) / person.dims.h : 798 / 3
          }px`}
        >
          <div class="name" style={`border-color: ${person.color}`}>
            {person.name}
            {#if person.dims?.w > 0}
              {person.dims.w}x{person.dims.h}
            {/if}
          </div>
          <!-- svelte-ignore a11y-media-has-caption -->
          <video
            bind:this={person.video}
            on:loadedmetadata={() =>
              (person.dims = {
                w: person.video.videoWidth,
                h: person.video.videoHeight,
              })}
            playsinline
            controls={false}
            autoplay={key !== meKey}
            muted={key === meKey}
          />
          {#if key === meKey}
            <div class="inputControls">
              <select bind:value={$videoId} class="video">
                {#each $videoInputs as input (input.deviceId)}
                  <option value={input.deviceId}
                    >{#if input.deviceId === $videoId}🎥 &nbsp;
                    {/if}{input.label || "Unknown camera"}</option
                  >
                {/each}
              </select>
              <select bind:value={$audioId} class="audio">
                {#each $audioInputs as input (input.deviceId)}
                  <option value={input.deviceId}
                    >{#if input.deviceId === $audioId}🎤 &nbsp;
                    {/if}{input.label || "Unknown microphone"}</option
                  >
                {/each}
              </select>
            </div>
            <div class="videoToggle">
              <button
                on:click|preventDefault={() => {
                  navigator.mediaDevices
                    .getUserMedia({
                      audio: {
                        deviceId: $audioId ? { exact: $audioId } : undefined,
                      },
                      video: {
                        deviceId: $videoId ? { exact: $videoId } : undefined,
                      },
                    })
                    .then(
                      (stream) => {
                        mediaStream.set(stream);
                        const video = $people[key].video;
                        video.srcObject = stream;
                        video.play();
                        return navigator.mediaDevices.enumerateDevices();
                      },
                      (e) => console.log("GUM error", e)
                    )
                    .then((devices) => {
                      if (devices) populateDevices(devices);
                    });
                }}>Start Video</button
              >
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</main>

<style>
  input[type="color"] {
    height: 1.3em;
    width: 1.3em;
    padding: 0;
  }
  main {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .controls {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .controls > div {
    position: absolute;
    padding: 5px;
    opacity: 0.1;
    background: transparent;
    transition: opacity 300ms ease-out, background-color 600ms ease-in-out;
  }
  .controls > div:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.4);
  }

  .editor {
    top: 0;
    left: 0;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
  }
  .messages {
    right: 0;
    width: 200px;
    height: calc(100% - 200px);
  }
  .room {
    right: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
  }
  .people {
    bottom: 0;
    width: calc(100% - 200px);
    height: 200px;
    display: flex;
    flex-direction: row;
  }
  .person {
    position: relative;
    border: 1px solid #aaa;
    margin-right: 8px;
  }
  .person video {
    width: 100%;
    height: 100%;
  }
  .name {
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline;
    padding: 2px;
    border-style: solid;
    border-width: 3px;
    opacity: 0.3;
  }
  .person:hover .name {
    opacity: 1;
  }
  .inputControls {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  .person:hover .inputControls {
    display: block;
  }
  .inputControls select {
    width: 3.4em;
  }
  .videoToggle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
